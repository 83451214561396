<template>
  <div>
    <div class="title">
      <img src="../../assets/img/blue-shi.png" alt="">
      <div class="title-txt">
        上海蓝十字脑科医院简介
      </div>
      <img src="../../assets/img/blue-shi.png" alt="">
    </div>
    <div class="content">
      <img src="../../assets/img/hosp.png" alt="" class="hospImg">
      <p class="text">
        <span class="bold">上海蓝十字脑科医院 </span>
        是在我国神经外科学先驱、原复旦大学附属华山医院院长陈公白教授鼎力支持下创建的一所脑病专科医院，也是同济大学附属脑科医院（筹）、国际JCI认证医院、国家脑防委“示范防治卒中中心”、美国芝加哥大学医学中心国际合作医院、上海市医保定点医院。医院按照三级专科医院标准建设，实行医疗、教学、科研、康复一体化模式，致力于创建现代化、国际化、专业化的脑科医院。
      </p>
      <div class="row">
        <img src="../../assets/img/address.png" alt="">
        <span class="bold size28 dark-color">医院地址：</span>
      </div>
      <p class="bold size28 dark-color">
        上海市闵行区七莘路2880号(七宝老街南500米)
      </p>
      <p class="text">
        医院以“专家建院、技术立院、创新兴院”为发展方针，以“258”学科建设计划（打造2个优势专科、建成5个特色专业科室、建设8个重点病种）为发展方向。医院技术特色鲜明，其中功能神经科、脑肿瘤外科、脑血管病专科与康复医学科等科室优势明显。
      </p>
      <p class="text">
        医院始终坚持正确的用人导向，建立重点学科带头人队伍，组建了一支医德高尚、医术精湛、学风严谨的专业团队。现在，医院拥有医学硕士20人、医学博士24人、硕士生导师10人、博士生导师5人、享受政府特殊津贴专家4人、副高级职称以上的专业技术人员81人、省部级及以上专业委员 18人、卫生技术人员占员工总数的81%。
      </p>
      <p class="text">
        医学学术成就方面：在核心期刊发表论文467篇、SCI论文25篇，科研成果33项，自然科学基金21项，参编学术专著48部。2018年，医院《DELP快速强化降低LDL-C治疗急性致残性缺血性卒中的随机对照研究》与《整合性口腔护理照护模式降低高龄老人吸入性肺炎发生率的研究》课题，分别获得吴阶平医学基金会临床科研专项资助基金课题立项，以及2018年度美Dreyfus健康基金会中国社区健康联盟护理中心PSBH科研课题。
      </p>
      <p class="text">
        医院配备先进的脑科疾病诊疗设施设备，包括飞利浦3.0T Ingenia MRI、128排256层iCT、双C臂DSA、瑞典医科达Synergy直线加速器、DR、彩超、高压氧舱群、全数字化Hybrid-OR复合手术室、美国尼高力视频脑电监测系统、术中神经监护系统、蔡司显微镜（双荧光）、德尔格麻醉机、日本光电监护仪、纽邦呼吸机、马奎手术床等，为临床构筑了精准可靠的诊疗系统。
      </p>
      <p class="text">
        <span class="bold"> “患者至上，质量为先”</span>
        医院医疗技术过硬、布局合理、设施先进、流程便捷，得到了政府、大众和业界的认可，并多次获得荣誉：2017年12月首次迎接社会医疗机构会主持的“星级评审”并获“四星示范医院”称号；2018年1月成功加入闵行区区域医疗联合体；2018年3月，上海闵行区医疗急救中心七宝分站在医院揭牌，医院正式纳入闵行区“120”急救指挥系统管理；2018年9月医院通过国际JCI认证，成为国内通过JCI(第六版)认证的脑专科医院；2019年6月，获评首批上海市“创新医疗服务品牌”；2019年7月获得上海市卫健康系统文明单位，并再度获得闵行区文明单位；2019年10月成为美国芝加哥大学医学中心国际合作医院；2019年12月医院成为国家卫健委脑防委“示范防治卒中中心”。
        <!--        <span class="bold blue-color"> “示范防治卒中中心” </span> 称号。-->
      </p>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style scoped lang="less">
.title{
  width: 500px;
  margin: 32px auto;
  display: flex;
  img{
    width:32px;
    height: 32px;
    position: relative;
    top: 9px;
  }
  .title-txt{
    font-size: 36px;
    font-weight: bold;
    color: #3D3D3D;
    margin: 0 16px;
  }
}
.content{
  padding: 0 32px 32px;
  .hospImg{
    width: 686px;
    height: 358px;
  }
  .text{
    text-indent: 60px;
    font-size: 28px;
    color: #3D3D3D;
    line-height: 46px;
  }
  .row{
    display: flex;
    margin-top: 24px;
    img{
      width: 30px;
      height: 30px;
      margin-right: 12px;
      margin-top: 3px;
    }
  }
}
</style>
